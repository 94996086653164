<template>
  <BrezelApp loading-animation="pulse">
    <template slot="logo">
      <div style="padding: 10px">
        <img src="/assets/logo.svg" style="width: 100%; max-height: 100px" alt="Logo">
      </div>
    </template>
    <template slot="spinner">
      <img src="/assets/512x512.png" width="128" height="128">
    </template>
    <template slot="loginLogo">
      <img src="/assets/logo.svg" :style="{ maxWidth: '100%', margin: '0 auto 26px auto', display: 'block' }" alt="logo">
    </template>
  </BrezelApp>
</template>

<script>
import BrezelApp from '@kibro/brezel-spa/src/components/App'

export default {
  name: 'App',
  components: {
    BrezelApp
  }
}
</script>

<style lang="scss">
[brezel-field-identifier=image_position] {
  label.ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item {
    &:before {
      background: transparent;
      position: relative;
      display: inline-block;
      width: 1.5em;
      height: 1em;
      vertical-align: -0.4em;
      margin-top: 5px;
      margin-right: 5px;
    }
  }

  label.ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item:nth-child(1) {
    &:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='black' d='M3 17V7h10v10zm2-2h6V9H5zM3 5V3h18v2zm12 4V7h6v2zm0 4v-2h6v2zm0 4v-2h6v2zM3 21v-2h18v2z'/%3E%3C/svg%3E");
    }

    &.ant-radio-button-wrapper-checked:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M3 17V7h10v10zm2-2h6V9H5zM3 5V3h18v2zm12 4V7h6v2zm0 4v-2h6v2zm0 4v-2h6v2zM3 21v-2h18v2z'/%3E%3C/svg%3E");
    }
  }

  label.ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item:nth-child(2) {
    &:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='black' d='M6 17h12l-3.75-5l-3 4L9 13zm-3 4V3h18v18z'/%3E%3C/svg%3E");
    }

    &.ant-radio-button-wrapper-checked:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M6 17h12l-3.75-5l-3 4L9 13zm-3 4V3h18v18z'/%3E%3C/svg%3E");
    }
  }

  label.ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item:nth-child(3) {
    &:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='black' d='M11 17V7h10v10zm2-2h6V9h-6zM3 21v-2h18v2zm0-4v-2h6v2zm0-4v-2h6v2zm0-4V7h6v2zm0-4V3h18v2z'/%3E%3C/svg%3E");
    }

    &.ant-radio-button-wrapper-checked:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M11 17V7h10v10zm2-2h6V9h-6zM3 21v-2h18v2zm0-4v-2h6v2zm0-4v-2h6v2zm0-4V7h6v2zm0-4V3h18v2z'/%3E%3C/svg%3E");
    }
  }
}
</style>
